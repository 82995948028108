/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {usePrevious} from 'react-use';
import CallIcon from '@mui/icons-material/Call';
import SettingsIcon from '@mui/icons-material/Settings';
import ShareIcon from '@mui/icons-material/Share';
import classNames from 'classnames';
import clsx from 'clsx';
// @ts-ignore
import {VC} from 'vidyoclient-nativewebrtc-sdk';

import {
    VIDEO_CUSTOM_BG_BASE64_SRC,
    VIDEO_DEFAULT_CAMERA_ID,
    VIDEO_DEFAULT_CAMERA_TITLE,
    VIDEO_DEFAULT_MIC_ID,
    VIDEO_DEFAULT_MIC_TITLE,
    VIDEO_DEFAULT_SPEAKER_ID,
    VIDEO_DEFAULT_SPEAKER_TITLE,
    VIDEO_FILTER_OPTION,
    VIDEO_IS_CUSTOM_BG_SELECTED,
} from '@/constants/constants';
import {VIDEO_PROVIDER_SETTINGS, videoProvider} from '@/services/videoService';
import {VideoFilterOptions} from '@/types/video';
import copyToClipboard from '@/utils/copyToClipboard';
import {generalNotify} from '@/utils/notifyMessages';

import {defaultBgImage} from './utils/defaultBgImage';
import {loadPrevSelectedDevice} from './utils/loadPrevSelectedDevice';
import {registerCamera} from './registerCamera';
import {registerMicrophone} from './registerMicrophone';
import {registerSpeaker} from './registerSpeaker';
import Settings from './Settings';
import {CustomSelect} from './SettingsDevices';
import {registerFilter, unregisterFilter} from './utils';

import './styles.css';
import styles from './videoCall.module.scss';

type VideoCallWidgetProps = {
    currentMeetingId: string;
    user: {
        firstName: string;
        id: number;
        lastName?: string;
    };
    onClose?: () => void;
    isForceLoadingState?: boolean;
    newWindowRef?: React.MutableRefObject<Window | null>;
    onDisconnect?: () => void;
    initCameraList?: SelectOption[];
};

export type SelectOption = {
    value: string;
    label: string;
};

export const VideoCallWidget = ({
    currentMeetingId: currentCallMeetingId,
    user,
    onClose,
    onDisconnect,
    isForceLoadingState = false,
    initCameraList = [],
}: VideoCallWidgetProps) => {
    const ref = useRef<HTMLIFrameElement>(null);
    const prevMeetingId = usePrevious(currentCallMeetingId);
    const [isLoading, setIsLoading] = useState(true);
    const [isConnected, setIsConnected] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [isCameraOn, setIsCameraOn] = useState(true);
    const [isMicrophoneOn, setIsMicrophoneOn] = useState(true);
    const [isSpeakerOn, setIsSpeakerOn] = useState(true);

    const [curMic, setCurMic] = useState<SelectOption | null>(null);
    const [curSpeaker, setCurSpeaker] = useState<SelectOption | null>(null);
    const [curCamera, setCurCamera] = useState<SelectOption | null>(null);
    const [cameraList, setCameraList] = useState<SelectOption[]>(initCameraList);
    const [micList, setMicList] = useState<SelectOption[]>([]);
    const [speakerList, setSpeakerList] = useState<SelectOption[]>([]);

    const handleClose = useCallback(() => {
        onDisconnect?.();
        setTimeout(() => {
            onClose?.();
        }, 1000);
    }, [onClose, onDisconnect]);
    const handleDisconnect = useCallback(() => {
        onDisconnect?.();
    }, [onDisconnect]);

    useEffect(() => {
        const connectionStatus = document.getElementById('connectionStatus');

        if (isForceLoadingState) {
            connectionStatus?.classList.add('loading');
        }
    }, [isForceLoadingState]);

    useLayoutEffect(() => {
        const fn = async () => {
            console.info('currentCallMeetingId', currentCallMeetingId);

            if (!currentCallMeetingId) {
                return;
            }

            if (prevMeetingId === currentCallMeetingId) return;

            window['vidyoConnector'] = await VC.CreateVidyoConnector({
                viewId: 'renderer',
                viewStyle: 'VIDYO_CONNECTORVIEWSTYLE_Default',
                remoteParticipants: 8,
                // logFileFilter: 'debug@VidyoClient debug@VidyoSDP debug@VidyoResourceManager',
                logFileFilter: '',
                logFileName: '',
                userData: 0,
                constraints: {},
            });

            if (!window.vidyoConnector) {
                console.error('CreateVidyoConnector failed');
                return;
            }

            await window.vidyoConnector.ConnectToRoomAsGuest({
                host: `${window.VIDYO_TENANT_URL}`,
                roomKey: currentCallMeetingId,
                roomPin: '1',
                displayName: `${user?.firstName} pid${user?.id}`,
                onSuccess: () => {
                    console.info(`vidyoConnector.ConnectToRoomAsGuest : onSuccess callback received`);
                    setIsLoading(false);
                    setIsConnected(true);
                },
                onFailure: (reason: any) => {
                    console.error('vidyoConnector.Connect : onFailure callback received', reason);
                    localStorage.setItem('VIDYO_ERROR', JSON.stringify(reason));
                    handleDisconnect();
                    setIsLoading(false);
                    setIsConnected(false);
                },
                onDisconnected: (reason: any) => {
                    console.info('vidyoConnector.Connect : onDisconnected callback received', reason);
                    setIsLoading(false);
                    setIsConnected(false);
                    onClose();
                },
            });

            await registerCamera({
                onAdded: (camera) => {
                    setCameraList((prev) => [
                        ...prev,
                        {
                            value: camera.id,
                            label: camera.name,
                        },
                    ]);
                },
                onRemoved: (cameraId) => {
                    setCameraList((prev) => {
                        const newList = prev.filter((camera) => camera.value !== cameraId);
                        if (curCamera?.value === cameraId) {
                            setCurCamera(newList[0] || null);
                        }
                        return newList;
                    });
                },
                setCurCamera: (camera) => {
                    setCurCamera({
                        value: camera.id,
                        label: camera.name,
                    });
                },
            });

            await registerSpeaker({
                onAdded: (speaker) => {
                    setSpeakerList((prev) => [
                        ...prev,
                        {
                            value: speaker.id,
                            label: speaker.name,
                        },
                    ]);
                },
                onRemoved: (speakerId) => {
                    setSpeakerList((prev) => {
                        const newList = prev.filter((speaker) => speaker.value !== speakerId);
                        if (curSpeaker?.value === speakerId) {
                            setCurSpeaker(newList[0] || null);
                        }
                        return newList;
                    });
                },
                setCurSpeaker: (speaker) => {
                    setCurSpeaker({
                        value: speaker.id,
                        label: speaker.name,
                    });
                },
            });
            await registerMicrophone({
                onAdded: (mic) => {
                    setMicList((prev) => [
                        ...prev,
                        {
                            value: mic.id,
                            label: mic.name,
                        },
                    ]);
                },
                onRemoved: (micId) => {
                    setMicList((prev) => {
                        const newList = prev.filter((mic) => mic.value !== micId);
                        if (curMic?.value === micId) {
                            setCurMic(newList[0] || null);
                        }
                        return newList;
                    });
                },
                setCurMic: (mic) => {
                    setCurMic({
                        value: mic.id,
                        label: mic.name,
                    });
                },
            });

            loadPrevSelectedDevice('camera');
            loadPrevSelectedDevice('microphone');
            loadPrevSelectedDevice('speaker');

            const savedCustomBg = localStorage.getItem(VIDEO_CUSTOM_BG_BASE64_SRC);
            const savedFilterOption = localStorage.getItem(VIDEO_FILTER_OPTION) as VideoFilterOptions;
            const savedIsCustomBgSelected = localStorage.getItem(VIDEO_IS_CUSTOM_BG_SELECTED);

            if (savedFilterOption === 'none') {
                return;
            }

            unregisterFilter();

            if (savedFilterOption === 'blur') {
                setTimeout(() => {
                    registerFilter(savedFilterOption).catch((err) => {
                        console.error(err);
                    });
                }, 0);
                return;
            } else if (savedFilterOption === 'bg_image') {
                setTimeout(() => {
                    registerFilter(
                        'bg_image',
                        savedIsCustomBgSelected && savedCustomBg ? savedCustomBg : defaultBgImage,
                    ).catch((err) => {
                        console.error(err);
                    });
                }, 0);
                return;
            }
        };

        fn().catch((err) => {
            console.error(err);
            localStorage.setItem('VIDYO_ERROR', JSON.stringify(err));
        });
    }, [
        currentCallMeetingId,
        handleClose,
        prevMeetingId,
        user?.firstName,
        user?.id,
        user.lastName,
        onDisconnect,
        handleDisconnect,
        curCamera?.value,
        curSpeaker?.value,
        curMic?.value,
        onClose,
    ]);

    const handleCameraPrivacy = useCallback(() => {
        if (isCameraOn) {
            window?.vidyoConnector.SetCameraPrivacy({privacy: true});
            setIsCameraOn(false);
        } else {
            window?.vidyoConnector.SetCameraPrivacy({privacy: false});
            setIsCameraOn(true);
        }
    }, [isCameraOn]);

    const handleMicrophonePrivacy = useCallback(() => {
        if (isMicrophoneOn) {
            window?.vidyoConnector.SetMicrophonePrivacy({privacy: true});
            setIsMicrophoneOn(false);
        } else {
            window?.vidyoConnector.SetMicrophonePrivacy({privacy: false});
            setIsMicrophoneOn(true);
        }
    }, [isMicrophoneOn]);

    const handleSpeakerPrivacy = useCallback(() => {
        if (isSpeakerOn) {
            window?.vidyoConnector.SetSpeakerPrivacy({privacy: true});
            setIsSpeakerOn(false);
        } else {
            window?.vidyoConnector.SetSpeakerPrivacy({privacy: false});
            setIsSpeakerOn(true);
        }
    }, [isSpeakerOn]);

    const handleMicChange = async (mic: any) => {
        const micValue = mic?.value;
        const curMicObj = window?.['microphone']?.find((microphone: any) => microphone.id === micValue);
        console.info('[VideoWidget] Microphone changed manually to: ' + `ID ${micValue} | Name ${curMicObj.name}`);
        setCurMic(mic);
        localStorage.setItem(VIDEO_DEFAULT_MIC_ID, micValue);
        localStorage.setItem(VIDEO_DEFAULT_MIC_TITLE, curMicObj.name);
        try {
            await window.vidyoConnector.SelectLocalMicrophone({
                localMicrophone: curMicObj,
            });
        } catch (error) {
            console.error('Error selecting microphone: ', error);
        }
    };

    const handleCameraChange = async (cam: any) => {
        const camValue = cam?.value;
        const curCamObj = window?.['camera']?.find((camera: any) => camera.id === camValue);
        console.info('[VideoWidget] Camera changed manually to: ' + `ID ${camValue} | Name ${curCamObj.name}`);
        setCurCamera(cam);
        localStorage.setItem(VIDEO_DEFAULT_CAMERA_ID, camValue);
        localStorage.setItem(VIDEO_DEFAULT_CAMERA_TITLE, curCamObj.name);
        try {
            await window.vidyoConnector.SelectLocalCamera({
                localCamera: curCamObj,
            });
        } catch (error) {
            console.error('Error selecting camera: ', error);
        }
    };

    const handleSpeakerChange = async (speaker: any) => {
        const speakerValue = speaker?.value;
        const curSpeakerObj = window?.['speaker']?.find((spk: any) => spk.id === speakerValue);
        console.info('[VideoWidget] Speaker changed manually to: ' + `ID ${speakerValue} | Name ${curSpeakerObj.name}`);
        setCurSpeaker(speaker);
        localStorage.setItem(VIDEO_DEFAULT_SPEAKER_ID, speakerValue);
        localStorage.setItem(VIDEO_DEFAULT_SPEAKER_TITLE, curSpeakerObj.name);
        try {
            await window.vidyoConnector.SelectLocalSpeaker({
                localSpeaker: curSpeakerObj,
            });
        } catch (error) {
            console.error('Error selecting speaker: ', error);
        }
    };

    return (
        <div
            id="videoCallWidget"
            ref={ref}
            className={styles.root}
            style={{
                visibility: 'visible',
                display: 'block',
                top: 0,
                left: 0,
                overflow: 'hidden',
                zIndex: 1000,
                pointerEvents: 'auto',
                width: `100%`,
                height: `100%`,
                minHeight: `100%`,
                minWidth: `100%`,
                backgroundColor: 'black',
            }}
        >
            {showSettings && (
                <Settings
                    onClose={() => {
                        setShowSettings(false);
                    }}
                    micList={micList}
                    speakerList={speakerList}
                    cameraList={cameraList}
                    curMic={curMic}
                    curSpeaker={curSpeaker}
                    curCamera={curCamera}
                    setCurMic={handleMicChange}
                    setCurSpeaker={handleSpeakerChange}
                    setCurCamera={handleCameraChange}
                />
            )}
            <div id="vidyoConnector" className={styles.vidyoConnector}>
                {isLoading && (
                    <div id="connectionStatus" className="loading">
                        <div className="connecting">
                            <CallIcon
                                className={classNames(styles.loadingIcon)}
                                style={{color: 'white', fontSize: '80px'}}
                            />
                            <div style={{color: 'white'}}>Connecting...</div>
                        </div>
                    </div>
                )}
                <div id="renderer-container" className={clsx(styles.rendererContainer, 'renderer-container')}>
                    <div id="renderer" className={styles.renderer}></div>
                </div>
                <div className={styles.toolbar}>
                    <div id="toolbarCenter" className={styles.toolbarCenter}>
                        <button
                            id="joinLeaveButton"
                            title="Join Conference"
                            className={classNames(styles.toolbarButton, isConnected ? 'callEnd' : 'callStart')}
                            onClick={() => {
                                handleClose();
                            }}
                        ></button>
                        <button
                            id="cameraButton"
                            title="Camera Privacy"
                            className={classNames(styles.toolbarButton, isCameraOn ? 'cameraOn' : 'cameraOff')}
                            onClick={() => {
                                handleCameraPrivacy();
                            }}
                        ></button>
                        <CustomSelect
                            dropdownOnly
                            label="Camera"
                            selectedValue={curCamera}
                            options={cameraList}
                            onChange={(e) => {
                                const cam = cameraList.find((c) => c.value === e.target.value);
                                handleCameraChange(cam);
                            }}
                        />
                        <button
                            id="microphoneButton"
                            title="Microphone Privacy"
                            className={classNames(
                                styles.toolbarButton,
                                isMicrophoneOn ? 'microphoneOn' : 'microphoneOff',
                            )}
                            onClick={() => {
                                handleMicrophonePrivacy();
                            }}
                        ></button>
                        <CustomSelect
                            dropdownOnly
                            label="Microphone"
                            selectedValue={curMic}
                            options={micList}
                            onChange={(e) => {
                                const mic = micList.find((m) => m.value === e.target.value);
                                handleMicChange(mic);
                            }}
                        />
                        <button
                            id="speakerButton"
                            title="Speaker Privacy"
                            className={classNames(styles.toolbarButton, isSpeakerOn ? 'speakerOn' : 'speakerOff')}
                            onClick={() => {
                                handleSpeakerPrivacy();
                            }}
                        ></button>
                        <CustomSelect
                            dropdownOnly
                            label="Speaker"
                            selectedValue={curSpeaker}
                            options={speakerList}
                            onChange={(e) => {
                                const speaker = speakerList.find((s) => s.value === e.target.value);
                                handleSpeakerChange(speaker);
                            }}
                        />
                        <button
                            id="optionsButton"
                            title="Copy Meeting Link"
                            className={classNames(styles.toolbarButton)}
                            onClick={() => {
                                copyToClipboard(
                                    videoProvider.getSharedLink({
                                        meetingId: currentCallMeetingId,
                                        portalUrl: VIDEO_PROVIDER_SETTINGS.VIDYO.portalUrl,
                                    }),
                                )
                                    .then(() => {
                                        generalNotify({
                                            title: 'Success',
                                            message: 'Meeting link copied to clipboard',
                                            status: 'success',
                                        });
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                    });
                            }}
                        >
                            <ShareIcon
                                style={{
                                    color: 'white',
                                    cursor: 'pointer',
                                    fontSize: '30px',
                                }}
                            />
                        </button>
                        <button
                            className={classNames(styles.toolbarButton)}
                            onClick={() => {
                                setShowSettings(true);
                            }}
                        >
                            <SettingsIcon />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
