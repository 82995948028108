import {VideoFilterOptions} from '@/types/video';

import {tensorFlowBodyPixInit} from './tensorFlowBodyPix';

declare global {
    interface Window {
        vidyoConnector: any;
    }
}

async function registerFilter(type: VideoFilterOptions, customBg?: string) {
    if (window['VIDYO_IS_BANUBA_ENABLED'] === true) {
        if (!window.banubaPluginReady) {
            return console.error('[VideoWidget] Banuba not ready');
        }

        if (type === 'blur') {
            console.info('[VideoWidget] Trying to enable blur effect');
            await window.banuba.applyEffect('blur');
            return window.vidyoConnector.RegisterLocalCameraStreamInterceptor(window.banuba.effectBackground);
        }

        if (type === 'bg_image') {
            console.info('[VideoWidget] Trying to enable background image effect');
            await window.banuba.applyEffect('custom_bg', customBg);
            return window.vidyoConnector.RegisterLocalCameraStreamInterceptor(window.banuba.effectBackground);
        }
    }

    if (window['VIDYO_IS_BANUBA_ENABLED'] === false) {
        console.info('[VideoWidget] Banuba is disabled. Trying to enable TensorFlow');

        if (type === 'blur') {
            const steamWrapper = await tensorFlowBodyPixInit(type);
            setTimeout(() => {
                window.vidyoConnector.RegisterLocalCameraStreamInterceptor(steamWrapper);
            }, 0);
            return;
        }

        if (type === 'bg_image') {
            const steamWrapper = await tensorFlowBodyPixInit(type, customBg);
            setTimeout(() => {
                window.vidyoConnector.RegisterLocalCameraStreamInterceptor(steamWrapper);
            }, 0);
            return;
        }
    }
}

async function unregisterFilter() {
    return await window.vidyoConnector.UnregisterLocalCameraStreamInterceptor();
}

export {registerFilter, unregisterFilter};
